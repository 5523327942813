:root {
  --header-height: 75px;

  --primary-1: #f3eeff;
  --primary-2: #e3d5ff;
  --primary-3: #cdb4ff;
  --primary-4: #b691ff;
  --primary-5: #a070ff;
  --primary-6: #8b51ff;
  --primary-7: #7645d9;
  --primary-8: #633ab5;
  --primary-9: #4f2e91;
  --primary-10: #3f2473;

  --secondary-1: #fff0f9;
  --secondary-2: #ffdaf1;
  --secondary-3: #ffbde6;
  --secondary-4: #ff9fda;
  --secondary-5: #ff82cf;
  --secondary-6: #ff66c4;
  --secondary-7: #d957a7;
  --secondary-8: #b5488b;
  --secondary-9: #913a70;
  --secondary-10: #732e58;

  --green-1: #f8ffe6;
  --green-2: #efffc2;
  --green-3: #e3ff91;
  --green-4: #d5ff5e;
  --green-5: #c9ff2e;
  --green-6: #bdff00;
  --green-7: #a1d900;
  --green-8: #86b500;
  --green-9: #6c9100;
  --green-10: #557300;
}

* {
  scrollbar-color: var(--primary-9) var(--primary-10);
  scrollbar-width: thin
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--primary-10);
  color: var(--green-1);
  font-size: medium;
}

@keyframes intermitente {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }

}

h1 {
  /* margin: 0;
  padding: 0; */
  font-size: 1.5em;
}

h2 {
  font-size: 1.3em;
}

button {
  background: var(--secondary-7);
  border: 0px;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 20px;
  color: white;
  cursor: pointer;
  font-size: 0.9em;
}

.MenuTop {
  height: var(--header-height);
  background: black;
  /* background-color: black; */
}

.Landing {
  background: url("../public/hero-bg.png");
  width: 100%;
  height: 100%;
  color: var(--primary-10);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Landing>div {
  animation: fadeIn 1s ease;
}

.Landing hr {
  width: 100%;
  max-width: 1000px;
  border-top: 1px solid var(--secondary-8);
  margin-bottom: 50px;
  margin-top: 0px;
}

.Landing-indicadores {
  display: flex;
  width: 100%;
  max-width: 1000px;
}

.Landing-mensaje {
  max-width: 700px;
  text-align: center;
  font-size: 1.2em;
}

.Landing-solicitud {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Landing-solicitud form input,
.Landing-solicitud form textarea {
  padding: 10px 20px;
  border-radius: 20px;
  border: 0px;
  margin: 10px;
  width: 300px;
  background: var(--primary-2);
}

.Landing-solicitud form textarea {
  height: 200px;
}

.Landing-solicitud form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Modal {
  width: 100%;
  height: calc(100% - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  font-size: medium;
  backdrop-filter: blur(6px);
  position: absolute;
}

.Modal-content {
  padding: 20px 30px;
  border-radius: 20px;
  background: var(--primary-2);
  color: var(--primary-10);
  box-shadow: 0px 0px 25px var(--primary-7);
  margin: 60px;
  position: relative;
}

.Modal-content-close {
  position: absolute;
  top: -20px;
  right: -30px;
  cursor: pointer;
  font-size: 2em;
  color: var(--primary-5);
}

.Loading-animation {
  animation: intermitente 1s infinite;
  font-size: 3em;
}

.GoDemos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - var(--header-height));
  align-items: center;
}

.CastingView,
.CastingViewFinal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.CastingViewFinal {
  background-color: black;
  color: var(--green-1);
}

.CastingViewFinal .CastingScript {
  background: var(--secondary-10);
  color: var(--secondary-1);
}

.CastingView-left {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  max-height: 100%;
  box-sizing: border-box;
  max-width: 600px;
}

.CastingView-center {
  padding-top: 30px;
}

.CastingDetail {
  font-size: 1em;
}

.CastingDetail-Title {
  font-style: italic;
  font-weight: 700;
  font-size: 1.5em;
}

.CastingDetail-Description {
  font-size: 1em;
  padding: 5px 0px;
}

.AuditionsList {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.AuditionDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
}

.AuditionDetail i {
  cursor: pointer;
}

.AudioCustomElement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.AudioCustomElement-controls {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CastingScript {
  white-space: pre-wrap;
  width: 400px;
  padding: 20px 20px;
  background: var(--green-10);
  border-radius: 20px;
  margin: 20px;
  font-size: 1.25em;
  min-height: 200px;
}

#progress_bar {
  width: 100%;
}

#oscilloscope {
  border-radius: 20px;
  background: var(--primary-2);
}

.ShareBox {
  text-align: center;
}

a.share-url {
  background: white;
  padding: 10px 20px;
  display: block;
  margin: 15px 0px;
  border-radius: 10px;
  font-family: monospace;
  border: 1px solid;
  color: var(--primary-10);
}

.DesktopViewComponentContainer {
  height: 100%;
  background: white;
  overflow: auto;
  width: 100%;
}

.CastingListComponent {
  display: table;
  width: 100%;
}


.CastingItemComponent {
  display: table-row;
  /* padding: 10px 20px; */
  /* border-radius: 20px; */
  background: var(--primary-2);
  /* margin: 10px; */
  /* box-shadow: 0px 0px 15px var(--primary-3); */
  color: var(--primary-10);
  font-size: 1em;
}

.CastingItemComponent>div {
  display: table-cell;
  padding: 10px 20px;
  border-bottom: 1px solid var(--primary-1);
}

div.estado>span {
  background: var(--primary-10);
  color: var(--primary-1);
  padding: 5px 10px;
  border-radius: 10px;
  white-space: nowrap;
  display: inline-block;
}

div.estado>span.open {
  background: var(--green-10);
  color: var(--green-1);
  font-family: monospace;
}

div.estado>span.closed {
  background: var(--secondary-10);
  color: var(--secondary-1);
  font-family: monospace;
}

div.enlace>span {
  background: var(--primary-10);
  color: var(--primary-1);
  padding: 9px 15px;
  border-radius: 10px;
  white-space: nowrap;
  display: inline-block;
}

div.enlace>i {
  cursor: pointer;
  margin: 5px;
}

div.enlace>span.link1 {
  background: var(--primary-10);
  color: var(--secondary-1)
}

div.enlace>span.link2 {
  background: black;
  color: var(--secondary-1);
}

a {
  color: inherit;
  text-decoration: none;
}


.CastingComponent {
  position: absolute;
  right: 0px;
  background: var(--green-1);
  color: var(--primary-10);
  width: 50%;
  height: calc(100% - var(--header-height));
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 30px var(--green-10);
  animation: slideFromRight 1s ease;
  overflow: auto;
}

.CastingComponent>div {
  margin: 5px;
}

.CastingComponent input,
.CastingComponent textarea {
  background: var(--green-2);
  padding: 5px 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  box-sizing: border-box;
}

.CastingComponent textarea {
  height: 200px;
}


@media screen and (max-width: 980px) {

  .GoDemos {
    height: auto;
  }

  .CastingView,
  .CastingViewFinal {
    flex-direction: column;
  }

  .CastingView .CastingScript,
  .CastingViewFinal .CastingScript {
    display: none;
  }


  .CastingComponent {
    width: 100%;
  }
  .CastingListComponent {
    display: block;
    width: 100%;
  }
  
  .CastingItemComponent{
    display: block;
    border-bottom: 1px solid var(--primary-1);
  }

  .CastingItemComponent>div {
    display: block;
    padding: 10px 20px;
    border: 0px;
  }


  .CastingItemComponent>div:first-child {
    width: 100%;
    box-sizing: border-box;
  }

  .CastingItemComponent>div:nth-child(2),
  .CastingItemComponent>div:nth-child(3),
  .CastingItemComponent>div:nth-child(4),
  .CastingItemComponent>div:nth-child(5),
  .CastingItemComponent>div:nth-child(6)  {
    display: inline-block;
  }
}

@media screen and (min-width: 681px) and (max-width: 980px) {
  .Landing {
    height: auto;
  }
}